import { CSS_CLASS_PREFIX, PAGE_URL } from '../../common/constants';
import './Header.scss';
import clsx from 'clsx';
import Menu from './Menu';

const Header = ({ path }) => {
  return (
    <header className={`${CSS_CLASS_PREFIX}header-root`}>
      <a
        className={clsx(
          `${CSS_CLASS_PREFIX}header-logo`,
          `${CSS_CLASS_PREFIX}header-logo-leber-software`,
        )}
        href={PAGE_URL}
      >
        Leber Software - László Leber
      </a>
      <Menu path={path} />
    </header>
  );
};

export default Header;
