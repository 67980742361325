import './About.scss';
import Career from './Career';
import Awards from './Awards';
import Layout from './Layout';
import { CSS_CLASS_PREFIX } from '../../common/constants';
import TopProjects from './TopProjects';
import Rating from '../Other/Rating';
import { useEffectSSR } from '../../common/utils';

const About = ({ path }) => {
  useEffectSSR(() => {
    if (path !== '') document.title = 'Leber Software - László Leber';
  }, [path]);

  return (
    <Layout path={path}>
      <section className={`${CSS_CLASS_PREFIX}main-intro`}>
        <span className={`${CSS_CLASS_PREFIX}main-intro-subtitle`}>
          Hey, I'm
        </span>
        <h1 className={`${CSS_CLASS_PREFIX}main-intro-name`}>László Leber</h1>
        <span className={`${CSS_CLASS_PREFIX}main-intro-subtitle`}>
          Welcome to Leber Software
        </span>
      </section>
      <section className={`${CSS_CLASS_PREFIX}global-section`}>
        <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
          <h2 className={`${CSS_CLASS_PREFIX}global-section-title`}>
            Experience
          </h2>
        </div>

        <div className={`${CSS_CLASS_PREFIX}global-section-content`}>
          <span>
            I am an experienced Senior Software Engineer with extensive
            programming expertise since 2002. I specialize in identifying
            customer needs and translating them into effective software
            solutions. I hold a degree in Computer Science and Engineering from
            the University of Pécs, earned in 2010. In 2019, I enhanced my
            skills by completing a UX Designer course, which provided me with
            modern software design methodologies that complement my academic
            background. This allows me to seamlessly integrate traditional
            software design techniques with contemporary approaches.
          </span>
          <span>
            Currently, I am employed at <b>Lufthansa Systems Hungaria</b> as
            a&nbsp;<b>Senior Software Engineer</b>, contributing to the digital
            transformation of the aviation industry.
          </span>
          <span>
            Additionally, I run a technology&nbsp;
            <b>
              <a
                href="https://www.youtube.com/@codeching"
                target="_blank"
                rel="noreferrer"
              >
                YouTube channel, "Codeching"
              </a>
              ,
            </b>
            &nbsp;where I share insights and tutorials on various technologies.
            I also maintain <b>this personal website (Leber Software)</b>
            &nbsp;featuring the <b>VR mobile games</b> I have developed for the
            Oculus platform.
          </span>
        </div>
      </section>

      <section className={`${CSS_CLASS_PREFIX}global-section`}>
        <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
          <h2 className={`${CSS_CLASS_PREFIX}global-section-title`}>Careers</h2>
        </div>
        <Career />
      </section>

      <section className={`${CSS_CLASS_PREFIX}global-section`}>
        <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
          <h2 className={`${CSS_CLASS_PREFIX}global-section-title`}>
            Honors & Awards
          </h2>
        </div>

        <Awards />
      </section>

      <section className={`${CSS_CLASS_PREFIX}global-section`}>
        <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
          <h2 className={`${CSS_CLASS_PREFIX}global-section-title`}>Skills</h2>
        </div>

        <div className={`${CSS_CLASS_PREFIX}global-section-content`}>
          Here you can find my most important tech skills as a software
          developer.
        </div>
        <div className={`${CSS_CLASS_PREFIX}main-technology`}>
          <div>
            <h3>My main technologies</h3>
            <ul>
              <li>
                <div>
                  <Rating value={5} />
                </div>
                React, Axios, Redux, RTK toolkit, SSR
              </li>
              <li>
                <div>
                  <Rating value={5} />
                </div>
                JEST, React Testing Library
              </li>
              <li>
                <div>
                  <Rating value={5} />
                </div>
                JavaScript, HTML/5, CSS/3 (+ SASS), JSS, Styled components
              </li>
              <li>
                <div>
                  <Rating value={5} />
                </div>
                JSON / XML
              </li>
              <li>
                <div>
                  <Rating value={4} />
                </div>
                Java, Spring, Spring-boot, Spring Data, JPA, Hybernate,
                Liquibase
              </li>
              <li>
                <div>
                  <Rating value={3} />
                </div>
                NodeJS / Express
              </li>
              <li>
                <div>
                  <Rating value={4} />
                </div>
                Gitlab CI/CD
              </li>
              <li>
                <div>
                  <Rating value={4} />
                </div>
                Argo CD
              </li>
              <li>
                <div>
                  <Rating value={4} />
                </div>
                Kubernetes
              </li>
              <li>
                <div>
                  <Rating value={5} />
                </div>
                Docker
              </li>
              <li>
                <div>
                  <Rating value={3} />
                </div>
                Cypress
              </li>
              <li>
                <div>
                  <Rating value={3} />
                </div>
                Angular 4+
              </li>
            </ul>
          </div>
          <div>
            <h3>Other technologies used</h3>
            <ul>
              <li>Android (Java), Unity (Oculus Virtual Reality platform)</li>
              <li>
                .NET Core MVC, ASP .NET MVC, ASP .NET WebForms, Entity
                FrameWork, LinQ
              </li>
              <li>PHP (frameworks, e.g. CodeIgniter)</li>
              <li>
                Delphi, Oracle PL/SQL, XNA, ExtJs, SqLite, Smarty, Redbeans ORM
              </li>
              <li>jQuery</li>
            </ul>
          </div>
        </div>
      </section>

      <section className={`${CSS_CLASS_PREFIX}global-section`}>
        <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
          <h2 className={`${CSS_CLASS_PREFIX}global-section-title`}>
            TOP hobby projects
          </h2>
        </div>

        <TopProjects />
      </section>
    </Layout>
  );
};

export default About;
