import { CSS_CLASS_PREFIX } from '../../common/constants';
import './Footer.scss';
import clsx from 'clsx';

const Footer = ({ className }) => {
  return (
    <footer className={clsx(`${CSS_CLASS_PREFIX}footer-root`, className)}>
      <div className={`${CSS_CLASS_PREFIX}footer-info`}>
        Copy right © 2008 - 2024 <span>László Leber</span>
        <span>All rights reserved.</span>
      </div>
      <div className={`${CSS_CLASS_PREFIX}footer-technology`}>
        This web page was written in <b>React</b> by László Leber and it is
        rendered on the&nbsp;
        <b>server-side (SSR)</b>
      </div>
    </footer>
  );
};

export default Footer;
