import { CSS_CLASS_PREFIX } from '../../../common/constants';
import Layout from '..//Layout';
import './Project.scss';
import { projects } from '../Projects';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Button from '../../Other/Button';

const Project = ({ id }) => {
  const project = projects.find((project) => project.id === id);

  return (
    <Layout className={`${CSS_CLASS_PREFIX}project-root`}>
      <div className={`${CSS_CLASS_PREFIX}project-header`}>
        <img
          className={`${CSS_CLASS_PREFIX}project-image`}
          src={project.imageSrc}
          alt={project.name}
        />
        <h1 className={`${CSS_CLASS_PREFIX}project-name`}>{project.name}</h1>
      </div>
      <section className={`${CSS_CLASS_PREFIX}project-data-root`}>
        <div className={`${CSS_CLASS_PREFIX}project-media`}>
          <div className={`${CSS_CLASS_PREFIX}project-carousel`}>
            <Carousel dynamicHeight={false} width={project.width || '100%'}>
              {project.images.map((image) => (
                <div key={image.title}>
                  <img src={image.src} alt={image.title} />
                  <p className="legend">{image.title}</p>
                </div>
              ))}
            </Carousel>
          </div>
          <div className={`${CSS_CLASS_PREFIX}project-video`}>
            <iframe
              title={project.name}
              src={project.videoURL}
              width="560"
              height="315"
              style={{ border: 'none' }}
              allowFullScreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
        <div className={`${CSS_CLASS_PREFIX}project-desc-and-download`}>
          <div className={`${CSS_CLASS_PREFIX}project-description`}>
            {project.longDescription}
          </div>
          <div className={`${CSS_CLASS_PREFIX}project-downloads`}>
            {project.downloadUrls.map((downloadUrlData) => (
              <Button
                key={downloadUrlData.title}
                link={downloadUrlData.url}
                target="_blank"
              >
                {downloadUrlData.title}
              </Button>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Project;
