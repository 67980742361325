import clsx from 'clsx';
import { CSS_CLASS_PREFIX } from '../../common/constants';
import './Button.scss';

const Button = ({ children, link, className, ...rest }) => {
  return (
    <a
      className={clsx(className, `${CSS_CLASS_PREFIX}button-root`)}
      href={link}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Button;
