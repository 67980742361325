import { CSS_CLASS_PREFIX } from '../../common/constants';
import './Rating.scss';

const Rating = ({ value }) => {
  const stars = [1, 2, 3, 4, 5];
  return (
    <div className={`${CSS_CLASS_PREFIX}rating-root`}>
      {stars.map((star) => {
        if (star > value)
          return (
            <div key={star} className={`${CSS_CLASS_PREFIX}rating-empty-star`}>
              {' '}
            </div>
          );
        return (
          <div key={star} className={`${CSS_CLASS_PREFIX}rating-star`}>
            {' '}
          </div>
        );
      })}
    </div>
  );
};

export default Rating;
