import './App.scss';
import { Route, Routes } from 'react-router-dom';
import About from './components/Content/About';
import Contact from './components/Content/Contact';
import Projects, { projects } from './components/Content/Projects';
import Project from './components/Content/Projects/Project';
import PrivacyPolicy from './components/Content/PolicyPages/PrivacyPolicy';
import Blog from './components/Content/Blog/Blog';

function App() {
  return (
    <>
      <Routes>
        <Route index element={<About />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="projects" element={<Projects />}></Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route
          path="bangsquash-privacy-policy"
          element={<PrivacyPolicy name="BangSquash" />}
        ></Route>
        <Route
          path="policy_avoidthedarkness"
          element={<PrivacyPolicy name="Avoid the Darkness" />}
        ></Route>
        <Route
          path="policy-mazeofthemummy"
          element={<PrivacyPolicy name="Maze of the Mummy" />}
        ></Route>
        <Route
          path="policy-chrons-assistant"
          element={<PrivacyPolicy name="Crohn's Assistant" />}
        ></Route>
        <Route path="blog" element={<Blog />}></Route>
        {projects.map((project) => (
          <Route
            key={`project-${project.id}`}
            path={project.path.replace('/', '')}
            element={<Project id={project.id} />}
          ></Route>
        ))}
      </Routes>
    </>
  );
}

export default App;
