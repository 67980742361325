import {
  CSS_CLASS_PREFIX,
  PAGE_BASE_PATH,
  PAGE_URL,
} from '../../common/constants';
import { getImagePath, useEffectSSR } from '../../common/utils';
import Layout from './Layout';
import './Projects.scss';
import fearTheLightsOutImg from '../../assets/projects/fear-the-lights-out.png';
import BangSquashImg from '../../assets/projects/bang-squash.png';
import MazeOfTheMummyImg from '../../assets/projects/maze-of-the-mummy.png';
import AvoidTheDarknessImg from '../../assets/projects/avoid-the-darkness.png';
import FishingTournamentImg from '../../assets/projects/fishing-tournament.png';
import EVMapiImg from '../../assets/projects/evmapi.png';

import Button from '../Other/Button';

export const projects = [
  {
    id: 'fear',
    name: 'Fear the Lights Out',
    description:
      'Do you remember the movie, “Lights out”. Do you remember the fear you felt when you watched it? This game is based on similar things like that movie. Just keep the lights on and you won’t have any problem ? The game will launch in Q2, 2020 for Oculus Quest.',
    imageSrc: getImagePath(
      typeof window !== 'undefined'
        ? fearTheLightsOutImg
        : 'SSR:fear-the-lights-out:SSR',
    ),
    y: 0,
    path: '/fear-the-lights-out',
    videoURL: 'https://www.youtube.com/embed/g_zZZ53Ipis',
    images: [
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/fear-the-lights-out-screen1.png`,
        title: 'Screenshot 1',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/fear-the-lights-out-screen2.png`,
        title: 'Screenshot 2',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/fear-the-lights-out-screen3.png`,
        title: 'Screenshot 3',
      },
    ],
    longDescription:
      'Do you remember the movie, “Lights out”. Do you remember the fear you felt when you watched it? This game is based on similar things like that movie. Just keep the lights on and you won’t have any problem ? The game will launch for Oculus Quest and Quest2.',
    downloadUrls: [
      {
        title: 'Download demo from itch.io',
        url: 'https://lebersoftware.itch.io/fear-the-lights-out-demo',
      },
    ],
  },
  {
    id: 'bang',
    name: 'BangSquash',
    description:
      'BangSquash is a block breaker-style game for the Oculus virtual reality (VR) platform. We believe BangSquash is an ideal game to introduce novice players to the basics of the Oculus platform or to enjoy a quick, engaging workout in just a few minutes. As an arcade game, each level can be replayed multiple times, with no two playthroughs being the same thanks to realistic physics. Every session unfolds differently, keeping the experience fresh and dynamic. The game launches with a full set of fun achievements and a global ranking system to enhance replayability. !!! An orientation-tracked controller is required to play this game.',
    imageSrc: getImagePath(
      typeof window !== 'undefined' ? BangSquashImg : 'SSR:bang-squash:SSR',
    ),
    y: 0,
    path: '/bang-squash',
    videoURL: 'https://www.youtube.com/embed/FCyRhbLN9Uc',
    images: [
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/bs-screen0.png`,
        title: 'Screenshot 1',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/bs-screen1.png`,
        title: 'Screenshot 2',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/bs-screen3.png`,
        title: 'Screenshot 3',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/bs-screen4.png`,
        title: 'Screenshot 4',
      },
    ],
    longDescription: (
      <div>
        <p>
          <strong>BangSquash</strong> is a block breaker-style game for&nbsp;
          <strong>Oculus platform </strong>. We believe Bang Squash to be an
          ideal game to show off the basic operation of the Oculus platform to
          novice players, or to get in a mini workout session in a couple of
          minutes. It is an arcade game, so each level could be repeatable many
          times and there aren’t two similar scenarios regarding the playing of
          levels, everything happens differently each time because of the
          realistic physics. The game launch with a whole set of fun
          achievements and an online ranking system (leaderboard) to increase
          replayability.
        </p>

        <div>
          <h3>
            <strong>Features</strong>
          </h3>
        </div>
        <p>
          You can play in numerous arenas (game levels) which have different
          difficulty levels and you must destroy colorful boxes which have
          different properties. Every arena has a unique color scheme and
          different obstacles/distribution of boxes. They have random
          royalty-free music, mainly Tech House.
        </p>
        <p>
          The geometry of the arenas are set up in such a way that the balls
          will always find their way back to the users side, however if they
          aren’t able to hit them in time, they lose them. Some of the challenge
          presented by the game comes from this requirement for good timing and
          precision. The player starts the game with a set number of (blue)
          balls.
        </p>
        <p>
          At any given time only a single blue ball can exist on the playing
          field, but breaking specific boxes might give the player the ability
          to play with multiple, differently colored additional balls
          simultaneously.
        </p>
        <p>
          The game is won by destroying all the boxes on the field, or lost if
          the player runs out of balls. The game will have a ranking system
          based on remaining lives and total time spent at the moment level
          completion.
        </p>

        <div>
          <h3>
            <strong>Gameobjects</strong>
          </h3>
        </div>
        <ul>
          <li>
            Rackets (left and right hand): The rackets have a hit and a catch
            mode the user can switch between with a button on the corresponding
            controllers.
          </li>
          <li>Blue ball: The ball the user starts out playing with.</li>
          <li>Green box: The most common box with no special properties.</li>
          <li>Yellow box: Creates another ball after collision.</li>
          <li>
            Purple box: Has to be hit multiple times to explode, remaining
            “lives” are displayed on the box.
          </li>
          <li>Pink box: Speeds up the balls that have collided with it.</li>
          <li>Blue box: Minifies the balls that have collided with it.</li>
          <li>
            Energy wall: Blocks the movement of balls while they are active.
          </li>
          <li>
            Yellow ball: Created by exploding yellow boxes. Can be used like the
            normal balls.
          </li>
        </ul>
        <div>
          <h3>
            <strong>Controls</strong>
          </h3>
        </div>
        <ul>
          <li>move your hands to use the rackets</li>
          <li>
            you can switch between hit&amp;catch mode with the primary and
            secondary hand trigger buttons (they are on the side of touch
            controllers)
          </li>
        </ul>
        <div>
          <h3>
            <strong>Online leaderboard</strong>
          </h3>
        </div>
        <p>You can register your account in-game.</p>
      </div>
    ),
    downloadUrls: [
      {
        title: 'Download for Oculus quest',
        url: 'https://www.oculus.com/experiences/quest/3843470232388706/',
      },
      {
        title: 'Download for Oculus GearVR & Go',
        url: 'https://www.oculus.com/experiences/go/2990946714347209/?ranking_trace=1424538224307905_2990946714347209_SKYLINEWEB_1431986220442261%3D%3D4cuhrbfUj7MbCiRS6',
      },
      {
        title: 'Buy now on itch.io',
        url: 'https://lebersoftware.itch.io/bangsquash/purchase?popup=1',
      },
      {
        title: 'Download demo from itch.io',
        url: 'https://lebersoftware.itch.io/bangsquash-demo',
      },
    ],
  },
  {
    id: 'maze',
    name: 'Maze of the Mummy',
    description:
      'This is a VR (Virtual reality) maze game for Android mobile phone, where you should collect treasures while several creatures (Mummy, spider,…) try to catch and disturb you in a dark pyramid.',
    imageSrc: getImagePath(
      typeof window !== 'undefined'
        ? MazeOfTheMummyImg
        : 'SSR:maze-of-the-mummy:SSR',
    ),
    y: 0,
    path: '/mazeofthemummy',
    videoURL: 'https://www.youtube.com/embed/DualrQEwHOo',
    images: [
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/mummy-1.png`,
        title: 'Screenshot 1',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/mummy-2.png`,
        title: 'Screenshot 2',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/mummy-3.png`,
        title: 'Screenshot 3',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/mummy-4.png`,
        title: 'Screenshot 4',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/mummy-5.png`,
        title: 'Screenshot 5',
      },
    ],
    longDescription:
      'This is a VR (Virtual reality) maze game for Android mobile phone, where you should collect treasures while several creatures (Mummy, spider,…) try to catch and disturb you in a dark pyramid. You will find traps in the maze as well so it won’t be an easy job for you to collect all the treasure and leave the maze. The maze, it’s size, the traps and monsters’ locations are random, so you will be able to play the game in many variations. The game is avaliable in the Oculus Gear VR store and android play store.',
    downloadUrls: [
      {
        title: 'Download for Oculus Gear VR',
        url: 'https://www.oculus.com/experiences/gear-vr/1427713014002514',
      },
    ],
  },
  {
    id: 'avoid',
    name: 'Avoid the Darkness',
    description:
      'This game is the proof of the existence of evil spirits. Get ready for the best VR horror experience in this fantastic horror game.',
    imageSrc: getImagePath(
      typeof window !== 'undefined'
        ? AvoidTheDarknessImg
        : 'SSR:avoid-the-darkness:SSR',
    ),
    y: 0,
    path: '/avoidthedarkness',
    videoURL: 'https://www.youtube.com/embed/PB74CS8vtGY',
    images: [
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/avoid-the-darkness-1.png`,
        title: 'Screenshot 1',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/avoid-the-darkness-2.png`,
        title: 'Screenshot 2',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/avoid-the-darkness-3.png`,
        title: 'Screenshot 3',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/avoid-the-darkness-4.png`,
        title: 'Screenshot 4',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/avoid-the-darkness-5.png`,
        title: 'Screenshot 5',
      },
    ],
    longDescription:
      'This game is the proof of the existence of evil spirits. Get ready for the best VR horror experience in this fantastic horror game. Play this with your friends, or guests. Let them to feel the fear as well 🙂 Turn on the lights as soon as possible or you will be in trouble! Focus on the signs, and you will be a survivor, or the darkness will kill you!',
    downloadUrls: [
      {
        title: 'Download for Oculus Gear VR',
        url: 'https://www.oculus.com/experiences/go/1784196901608145',
      },
    ],
  },
  {
    id: 'fishing',
    name: 'Fishing Tournament',
    description:
      '3D Fishing game with online functions. You can use your boat and find a fishing spot with it, or you can walk around the lake also.',
    imageSrc: getImagePath(
      typeof window !== 'undefined'
        ? FishingTournamentImg
        : 'SSR:fishing-tournament:SSR',
    ),
    y: 0,
    path: '/fishing-tournament',
    videoURL: 'https://www.youtube.com/embed/fV9z0qXt20Y',
    images: [
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/fishing1.jpg`,
        title: 'Screenshot 1',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/fishing2.jpg`,
        title: 'Screenshot 1',
      },
    ],
    longDescription:
      '3D Fishing game with online functions. You can use your boat and find a fishing spot with it, or you can walk around the lake also. You can fishing anywhere, feed the fishes. If you catch a fish, it is stored in an online log. You can see the online fishing log on this web page. You can also chat with your friends.',
    downloadUrls: [
      {
        title: 'Download user manual',
        url: `${PAGE_URL}${PAGE_BASE_PATH}/assets/Fishing-Tournament_2011_2014_manual.pdf`,
      },
      {
        title: 'Download',
        url: `${PAGE_URL}${PAGE_BASE_PATH}/assets/Setup_Fst_v13_2014.zip`,
      },
    ],
  },
  {
    id: 'evmapi',
    name: 'EV mapi',
    description:
      'EVmapi is an online tool where you can export charging station points from your computer.',
    imageSrc: getImagePath(
      typeof window !== 'undefined' ? EVMapiImg : 'SSR:evmapi:SSR',
    ),
    y: 0,
    path: '/ev-mapi',
    videoURL: 'https://www.youtube.com/embed/lVOToi42CqY',
    images: [
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/ev1.jpg`,
        title: 'Screenshot 1',
      },
      {
        src: `${PAGE_URL}${PAGE_BASE_PATH}/assets/screenshots/ev2.jpg`,
        title: 'Screenshot 1',
      },
    ],
    longDescription:
      'EVmapi is an online tool where you can export charging station points from your computer. You can filter the stations before export and you can choose export format, electric car brand and model.',
    downloadUrls: [
      {
        title: 'Visit EVmapi website',
        url: 'https://www.evmapi.lebersoftware.hu',
      },
    ],
    width: 300,
  },
];

const Projects = ({ path }) => {
  useEffectSSR(() => {
    document.title = 'Leber Software - Projects';
  }, []);

  return (
    <Layout className={`${CSS_CLASS_PREFIX}projects-root`} path={path}>
      <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
        <h1 className={`${CSS_CLASS_PREFIX}global-section-title`}>
          My hobby projects
        </h1>
      </div>
      <div className={`${CSS_CLASS_PREFIX}projects-description`}>
        Currently, I am employed at <b>Lufthansa Systems Hungaria</b> as a&nbsp;
        <b>Senior Software Engineer</b>, contributing to the digital
        transformation of the aviation industry. But in my free time I developed
        several games for Oculus VR platform and a Fishing game for windows
        environment which you can find here.
      </div>
      <section className={`${CSS_CLASS_PREFIX}projects-projects`}>
        {projects.map((project, i) => (
          <div
            key={i + 0}
            className={`${CSS_CLASS_PREFIX}projects-project`}
            style={{ position: 'relative', top: project.y }}
          >
            <img
              src={project.imageSrc}
              className={`${CSS_CLASS_PREFIX}projects-project-image`}
              alt={project.name}
            />
            <div className={`${CSS_CLASS_PREFIX}projects-project-data`}>
              <h3 className={`${CSS_CLASS_PREFIX}projects-project-name`}>
                {project.name}
              </h3>
              <div
                className={`${CSS_CLASS_PREFIX}projects-project-description`}
              >
                {project.description}
              </div>
              <Button link={`${PAGE_BASE_PATH}${project.path}`}>
                Read more
              </Button>
            </div>
          </div>
        ))}
      </section>
    </Layout>
  );
};

export default Projects;
