import { CSS_CLASS_PREFIX, PAGE_BASE_PATH } from '../../common/constants';
import { useEffectSSR, useRefSSR, useStateSSR } from '../../common/utils';
import './TopProjects.scss';
import Button from '../Other/Button';
import { projects as allProject } from './Projects';

const TopProjects = () => {
  const [projects, setProjects] = useStateSSR(allProject.slice(0, 3));

  const container = useRefSSR(null);

  useEffectSSR(() => {
    if (!container || !container.current || window.innerWidth < 850) return;
    const scrollEvent = window.addEventListener('scroll', () => {
      setProjects((prev) => [
        ...prev.map((project, i) => {
          let y = 0;

          var viewportOffset = container.current.getBoundingClientRect();
          if (i === 0) {
            y = 20; // container.current.
          }
          let pos = 900 - viewportOffset.top;

          if (pos < 0) pos = 0;
          if (pos < -50) pos = 0;
          pos = 85 - pos / 8;

          if (i === 0) pos = -pos;
          if (i === 1) pos = y;

          return {
            ...project,
            y: pos,
          };
        }),
      ]);
    });
    return () => {
      window.removeEventListener('scroll', scrollEvent);
    };
  }, [container]);

  return (
    <div ref={container} className={`${CSS_CLASS_PREFIX}top-projects-root`}>
      {projects.map((project, i) => (
        <div
          key={i + 0}
          className={`${CSS_CLASS_PREFIX}top-projects-project`}
          style={{ position: 'relative', top: project.y }}
        >
          <img
            src={project.imageSrc}
            className={`${CSS_CLASS_PREFIX}top-projects-project-image`}
            alt={project.name}
          />
          <div className={`${CSS_CLASS_PREFIX}top-projects-project-data`}>
            <h3 className={`${CSS_CLASS_PREFIX}top-projects-project-name`}>
              {project.name}
            </h3>
            <div
              className={`${CSS_CLASS_PREFIX}top-projects-project-description`}
            >
              {project.description}
            </div>
            <Button link={`${PAGE_BASE_PATH}${project.path}`}>Read more</Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopProjects;
