import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { PAGE_BASE_PATH } from './common/constants';

// const onRecoverableError = (error, errInfo) => {
//   console.log(error, errInfo);
//  }
//  { onRecoverableError }

ReactDOM.hydrateRoot(
  document.getElementById('root'),
  <React.StrictMode>
    <BrowserRouter basename={PAGE_BASE_PATH}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
