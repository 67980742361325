import { CSS_CLASS_PREFIX } from '../../common/constants';
import './SocialMediaSection.scss';
import clsx from 'clsx';

const SocialMediaSection = ({ className }) => {
  return (
    <ul
      className={clsx(
        `${CSS_CLASS_PREFIX}social-media-section-root`,
        className,
      )}
    >
      <li>
        <a
          className={clsx(
            `${CSS_CLASS_PREFIX}social-media-section-logo`,
            `${CSS_CLASS_PREFIX}social-media-section-social-media-logo`,
            `${CSS_CLASS_PREFIX}social-media-section-logo-x`,
          )}
          href="https://x.com/codeching"
          target="_blank"
          rel="noreferrer"
        >
          twitter
        </a>
      </li>
      <li>
        <a
          className={clsx(
            `${CSS_CLASS_PREFIX}social-media-section-logo`,
            `${CSS_CLASS_PREFIX}social-media-section-social-media-logo`,
            `${CSS_CLASS_PREFIX}social-media-section-logo-linked-in`,
          )}
          href="https://hu.linkedin.com/in/l%C3%A1szl%C3%B3-leber-2351a3152"
          target="_blank"
          rel="noreferrer"
        >
          linkedin
        </a>
      </li>
      <li>
        <a
          className={clsx(
            `${CSS_CLASS_PREFIX}social-media-section-logo`,
            `${CSS_CLASS_PREFIX}social-media-section-social-media-logo`,
            `${CSS_CLASS_PREFIX}social-media-section-logo-facebook`,
          )}
          href="https://www.facebook.com/lebersoftware"
          target="_blank"
          rel="noreferrer"
        >
          facebook
        </a>
      </li>
    </ul>
  );
};

export default SocialMediaSection;
