import './Awards.scss';
import award from '../../assets/prof_award_laszlo_leber.jpg';
import { getImagePath } from '../../common/utils';
import { CSS_CLASS_PREFIX } from '../../common/constants';

const Awards = () => {
  return (
    <div className={`${CSS_CLASS_PREFIX}awards-root`}>
      <ul>
        <li>
          2024 May: <strong>Professional Excellence Award</strong> Lufthansa
          Systems – team award – We would like to nominate the whole AERQ team
          @LSYH for the Professional Excellence Award because of their
          outstanding contribution to the enter-into service of the AERQ AERENA
          Entertainment System. After a long journey started in 2020 and very
          challenging months at the end the next generation of IFE was able to
          take off at Discover Airlines in April and operating smootly since
          then. The team was able to cope with the continously changing
          expectations, introduced state of the art technologies and established
          a professional mindset, enthusiasm and expertise without which this
          product surely couldn't have been born. Congratulations to the whole
          team!
        </li>
        <li className={`${CSS_CLASS_PREFIX}awards-award`}>
          <div className={`${CSS_CLASS_PREFIX}awards-award-content`}>
            <div>
              2021 November: <strong>Professional Excellence Award</strong>
            </div>
            <div>
              <a
                href={getImagePath(
                  typeof window !== 'undefined'
                    ? award
                    : 'SSR:prof_award_laszlo_leber:SSR',
                )}
              >
                <img
                  className={`${CSS_CLASS_PREFIX}awards-award-image`}
                  src={getImagePath(
                    typeof window !== 'undefined'
                      ? award
                      : 'SSR:prof_award_laszlo_leber:SSR',
                  )}
                  alt="Professional excellence award - László Leber - Lufthansa Systems"
                />
              </a>
            </div>
            <div>
              Lufthansa Systems – personal award – I made the architecture and
              most of the implementation of a React component library and other
              integration parts which provide seamless transition between our
              current project family’s systems. It provides SSO (Single
              Sign-on), authentication (based on OpenID), authorization, top
              navigation UI bar and navigation system. It is integrated by many
              teams to their projects in the same project family around the
              world to make the connection between the systems and allow the
              user to navigate between them without re-login. It communicates
              with Keycloak, User manager services
            </div>
          </div>
        </li>
        <li>
          <div>
            2021 : <strong>Professional Excellence Award</strong>
          </div>
          Lufthansa Systems – team award – Control Tower team
        </li>
        <li>
          2020 : <strong>Connect + Award</strong> Lufthansa Systems
        </li>
      </ul>
    </div>
  );
};

export default Awards;
