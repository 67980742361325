import './UpArrow.scss';
import { ReactComponent as UpArrowIcon } from '../../assets/up-arrow.svg';
import { useStateSSR, useLayoutEffectSSR } from '../../common/utils';
import { CSS_CLASS_PREFIX } from '../../common/constants';

const UpArrow = () => {
  const [visible, setVisible] = useStateSSR(false);

  useLayoutEffectSSR(() => {
    const scrollEvent = window.addEventListener('scroll', () => {
      if (window.scrollY < window.innerHeight / 2) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  const scrollToUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!visible) return;

  return (
    <UpArrowIcon
      className={`${CSS_CLASS_PREFIX}up-arrow-root`}
      onClick={scrollToUp}
    />
  );
};

export default UpArrow;
