import { CSS_CLASS_PREFIX, PAGE_BASE_PATH } from '../../../common/constants';
import { useEffectSSR, useStateSSR } from '../../../common/utils';
import Button from '../../Other/Button';
import Layout from '..//Layout';
import './Blog.scss';

const Blog = ({ path, allowFetch = true }) => {
  useEffectSSR(() => {
    document.title = "Leber Software - Blog"
 }, []);

  const [data, setData] = useStateSSR((typeof window !== 'undefined' && window.ssrData && window.ssrData.rssFeedData) || null);
  useEffectSSR(() => {
    const fetchPosts = async () => {
      if (allowFetch && data === null) {
        if (!window.ssrData){
          const url = `${PAGE_BASE_PATH}/rss`;
          const rssData = await fetch(url);
          setData(await rssData.json());
        }
      }
    };
    fetchPosts();
  }, [data]);

  return (
    <Layout path={path} className={`${CSS_CLASS_PREFIX}blog-root`}>
      <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
        <h1 className={`${CSS_CLASS_PREFIX}global-section-title`}>Blog</h1>
      </div>
      <section className={`${CSS_CLASS_PREFIX}blog-content`}>
        Codeching is my blog offering a wide range of fascinating tutorials on
        Docker, Kubernetes, Azure, React, Node.js, Nginx, Angular, Java, CSS,
        and state management systems such as Redux, ContextAPI, Recoil, RxJS,
        and many more. Whether you're a DevOps engineer or a developer, this
        blog is for you.
      </section>
      <section className={`${CSS_CLASS_PREFIX}blog-links`}>
        <Button link="https://medium.com/@codeching">Medium</Button>
        <Button link="https://www.youtube.com/@codeching">Youtube</Button>
      </section>
      <section
        suppressHydrationWarning
        className={`${CSS_CLASS_PREFIX}blog-latest-posts`}
      >
        <h2>Latest blog posts</h2>
        {!data && <div suppressHydrationWarning>SSR-REPLACE_BLOG_POSTS-SSR</div>}
        {data &&
          !!data.length &&
          <div>{data.map((post, i) => (
            <div key={`${post.url}${i}`} className={`${CSS_CLASS_PREFIX}blog-latest-post`}>
              <div className={`${CSS_CLASS_PREFIX}blog-latest-post-date`}>
                {post.date}
              </div>
              <a href={post.link} target="_blank" rel="noreferrer">
                {post.title}
              </a>
            </div>
          ))}</div>}
      </section>
    </Layout>
  );
};

export default Blog;
