import { CSS_CLASS_PREFIX, PAGE_BASE_PATH } from '../../common/constants';
import HamburgerIcon from '../../assets/HamburgerIcon';
import './Menu.scss';
import { useStateSSR } from '../../common/utils';
import clsx from 'clsx';
import SocialMediaSection from './SocialMediaSection';

const Menu = ({ path }) => {
  const [menuClasses, setMenuClasses] = useStateSSR('');

  const toogleMobileMenu = () => {
    setMenuClasses((prev) => (!!prev ? '' : 'menu-opened'));
  };

  const menuPaths = [
    { path: '/about', name: 'about' },
    { path: '/projects', name: 'projects' },
    { path: '/contact', name: 'contact' },
    { path: '/blog', name: 'blog' },
  ];

  return (
    <nav className={`${CSS_CLASS_PREFIX}menu-root`}>
      <HamburgerIcon
        onClick={toogleMobileMenu}
        className={`${CSS_CLASS_PREFIX}menu-hamburger-icon`}
      />

      <ul className={clsx(`${CSS_CLASS_PREFIX}menu-nav`, menuClasses)}>
        {menuPaths.map((menu) => (
          <li
            key={menu.path}
            className={clsx(
              `${CSS_CLASS_PREFIX}menu-nav-item`,
              ((path &&
                (menu.path === path ||
                  (path === '/' && menu.path === '/about'))) ||
                (typeof window !== 'undefined' &&
                  (window.location.pathname === menu.path ||
                    (window.location.pathname === '/' &&
                      menu.path === '/about')))) &&
                `${CSS_CLASS_PREFIX}menu-active`,
            )}
            onClick={toogleMobileMenu}
          >
            <a href={`${PAGE_BASE_PATH}${menu.path}`}>{menu.name}</a>
          </li>
        ))}
      </ul>

      <SocialMediaSection className={menuClasses} />
    </nav>
  );
};

export default Menu;
