import { CSS_CLASS_PREFIX } from '../../common/constants';
import Layout from './Layout';
import './Contact.scss';
import { useEffectSSR } from '../../common/utils';

const Contact = ({ path }) => {
  useEffectSSR(() => {
    document.title = "Leber Software - Contact"
 }, []);

  return (
    <Layout
      path={path}
      className={`${CSS_CLASS_PREFIX}contact-root`}
      footerClassName={`${CSS_CLASS_PREFIX}contact-footer`}
    >
      <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
        <h1 className={`${CSS_CLASS_PREFIX}global-section-title`}>Contact</h1>
      </div>
      <section className={`${CSS_CLASS_PREFIX}contact-data-root`}>
        E-mail:&nbsp;
        <a href="mailto:info&#64;lebersoftware&#46;hu">
          info&#64;lebersoftware&#46;hu
        </a>
      </section>
    </Layout>
  );
};

export default Contact;
