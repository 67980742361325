import Header from '../Header/Header';
import UpArrow from '../Other/UpArrow';
import Footer from '../Footer/Footer';
import { CSS_CLASS_PREFIX } from '../../common/constants';
import clsx from 'clsx';

const Layout = ({ path, className, footerClassName, children }) => {
  return (
    <>
      <Header path={path} />
      <UpArrow />
      <div className={clsx(`${CSS_CLASS_PREFIX}main`, className)}>
        {children}
      </div>
      <Footer className={footerClassName} />
    </>
  );
};

export default Layout;
