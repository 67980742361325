import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

const getImagePath = (path) => {
  if (typeof window !== 'undefined') {
    return path;
  } else {
    // SSR
    return `${process.env.BASE_PATH}/static/media/${path}`;
  }
};

const useStateSSR =
  typeof window !== 'undefined'
    ? useState
    : (defaultValue) => {
        return [defaultValue, () => {}];
      };
const useLayoutEffectSSR =
  typeof window !== 'undefined' ? useLayoutEffect : () => {};
const useEffectSSR = typeof window !== 'undefined' ? useEffect : () => {};
const useMemoSSR = typeof window !== 'undefined' ? useMemo : () => {};
const useRefSSR = typeof window !== 'undefined' ? useRef : () => <div></div>;

export {
  getImagePath,
  useStateSSR,
  useLayoutEffectSSR,
  useEffectSSR,
  useRefSSR,
  useMemoSSR,
};
