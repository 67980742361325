import { CSS_CLASS_PREFIX } from '../../../common/constants';
import { useEffectSSR } from '../../../common/utils';
import Layout from '../Layout';
import './PrivacyPolicy.scss';

const PrivacyPolicy = ({ name }) => {
  useEffectSSR(() => {
    if (name !== '') document.title = `Leber Software - ${name} Privacy Policy`;
  }, [name]);

  return (
    <Layout className={`${CSS_CLASS_PREFIX}privacy-root`}>
      <div className={`${CSS_CLASS_PREFIX}global-section-title-container`}>
        <h1 className={`${CSS_CLASS_PREFIX}global-section-title`}>
          {name} Privacy Policy
        </h1>
      </div>
      <section className={`${CSS_CLASS_PREFIX}privacy-data`}>
        <p>
          Protecting your privacy is important to us. We hope the following
          statement will help you understand how our app({name}) deals with the
          personal identifiable information "PII".
        </p>

        <p>
          We do not collect any PII from you when you download our applications.
          To be specific, we do not require the consumers to get registered
          before downloading the application.
        </p>

        <p>
          Our app communicates with our server (lebersoftware.hu) via Internet.
          &nbsp;
          <strong>
            We don't store any personally identifiable information on our
            server.
          </strong>
          &nbsp;We only store technical data (<b>USER_ID</b>), which is required
          to make the communication between the client and server, and to be
          able to store your scores for the leaderboard.&nbsp; We're not storing
          any profile information, however we're using the <b>USER_PROFILE</b>
          &nbsp; inside our game to be able to display your name on the
          UI.&nbsp;
        </p>

        <p style={{ marginBottom: 40 }}>
          If you would like to delete your stored USER_ID from our database,
          please write an e-mail to info@lebersoftware.hu
        </p>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
