const PAGE_URL =
  typeof window !== 'undefined'
    ? window.location.origin
    : process.env.PUBLIC_URL || '';
const PAGE_BASE_PATH =
  typeof window !== 'undefined' ? window.basePath : process.env.BASE_PATH || '';
const PAGE_NAME = 'László Leber';
const CSS_CLASS_PREFIX = 'leber-';

export { PAGE_NAME, PAGE_URL, PAGE_BASE_PATH, CSS_CLASS_PREFIX };
