import { CSS_CLASS_PREFIX } from '../../common/constants';
import './Career.scss';

const Career = () => {
  const careerData = [
    {
      date: 'Current - Mar 2020',
      title: 'Lufthansa Systems Hungária',
      place: 'Budapest - full time',
      role: 'Senior software engineer',
      description:
        'React, Node, DevOps engineer | Passenger Experience Unit / AERQ Control Tower team | Contributing to the digital transformation of the aviation industry.',
    },
    {
      date: 'Mar 2020 - Oct 2019',
      title: 'Lufthansa Systems Hungária',
      place: 'Budapest - full time',
      role: 'Professional software engineer',
      description:
        'React, Node, DevOps engineer | Passenger Experience Unit / AERQ Control Tower team | Contributing to the digital transformation of the aviation industry.',
    },
    {
      date: 'Nov 2019 - Jul 2019',
      title: 'Paks II. Nuclear Power Plant Ltd.',
      place: 'Paks - full time',
      role: 'Information Technology Expert',
      description: '',
    },
    {
      date: 'Jul 2019 - Mar 2015',
      title: 'Govern-Soft Kft.',
      place: 'Paks - full time',
      role: 'Lead of Software development department / Senior Software Development Engineer',
      description:
        '.NET Core 2.0 MVC, Angular 5 | ASP.NET WebForms, jQuery | https://govern.hu',
    },
    {
      date: 'Mar 2015 - Feb 2011',
      title: 'Govern-Soft Kft.',
      place: 'Paks - full time',
      role: 'Software Engineer',
      description: 'Delphi,PHP',
    },
  ];

  return (
    <div className={`${CSS_CLASS_PREFIX}career-root`}>
      <div className={`${CSS_CLASS_PREFIX}career-line`}>
        {careerData.map((_, i) => (
          <div key={i + 0} className={`${CSS_CLASS_PREFIX}career-line-part`}>
            {' '}
          </div>
        ))}
      </div>
      <div className={`${CSS_CLASS_PREFIX}career-stages`}>
        {careerData.map((data) => (
          <div key={data.date} className={`${CSS_CLASS_PREFIX}career-stage`}>
            <h3 className={`${CSS_CLASS_PREFIX}career-title`}>
              <div className={`${CSS_CLASS_PREFIX}career-circle`}> </div>
              {data.title}
            </h3>
            <div className={`${CSS_CLASS_PREFIX}career-date`}>{data.date}</div>
            <div className={`${CSS_CLASS_PREFIX}career-place`}>
              <h4>{data.role}</h4>
              {` - ${data.place}`}
            </div>
            <div className={`${CSS_CLASS_PREFIX}career-description`}>
              {data.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Career;
